import React from "react"
import { Link } from "gatsby"
import HeroImage from "../../../contents/Home/HeroImage"
import SvgDevices from "../../../contents/Home/SvgDevices"
import SvgDeadPixelRepair from "../../../contents/Home/SvgDeadPixelRepair"
import SvgStuckPixelRepair from "../../../contents/Home/SvgStuckPixelRepair"
import SEO from "../../../components/Seo"
const HomePage = () => {
  return (
    <>
      <SEO
        title="Home"
        lang="en"
        description="Test for dead pixels, stuck pixels, repair dead pixels and stuck pixels on your Monitors, Displays, Tablets, Computers and TVs."
        keywords="dead pixel test, stuck pixel test, stuck pixel repair, dead pixel repair, stuck pixel test, what is dead pixel, what is stuck pixel, what is stuck pixel, dead pixel stuck pixel difference, dead pixel test, pixel test, dead pixel, dead pixel, pixel test, stuck pixel, pixel repair online"
      />
      <h1 className="pb-0 mb-0 text-center">
        Check for&nbsp;
        <span className="text-indigo-600">dead</span>&nbsp;or&nbsp;
        <span className="text-red-500">stuck</span> pixels!
      </h1>

      <HeroImage className="max-w-full px-3 md:px-10" />

      <p className="lead">
        You can test for <strong>dead pixels</strong> or{" "}
        <span className="font-bold text-green-400">stuck pixels</span> on your
        mobile phones, tablets, televisions and computers via the browser and
        use the{" "}
        <span className="font-bold text-purple-600">
          free pixel repair tool
        </span>{" "}
        without any installation.
      </p>
      <h2>Dead or Stuck Pixel Test</h2>
      <p>
        Whether tablet, phone, or monitor... When you buy any device with a
        screen, you need to do the <strong>dead pixel test</strong> and the{" "}
        <strong>stuck pixel test</strong> after installation.
      </p>
      <p>
        The sooner you notice a potential defect in the product you purchased,
        the easier it will be to process the return and exchange. Moreover, this
        process does not take much time, you can easily test dead pixels and
        stuck pixels in a few minutes.
      </p>
      <SvgDevices className="max-w-full px-3 md:px-10" />

      <p>
        You can start the test by going to the corresponding page by clicking
        the <code>test dead pixel</code> button below. In the page that opens,
        there is detailed information about the dead pixel and stuck pixel test
        as well as the test tool.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/dead-pixel-test"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Test Dead Pixel</span>
        </Link>
      </div>
      <h2>How to repair dead pixel, fix dead pixel problem?</h2>
      <p>
        A dead pixel means that a pixel on the screen has reached the end of its
        life. Since it does not take energy, it is generally black in color.
        There is no software solution to fix the dead pixel problem, usually
        panel and screen replacement is required for this.{" "}
        <span className="font-bold underline">Pushing down softly</span> may
        work if there are dead pixels on LCD screens caused by the emission of
        liquid crystals.
      </p>
      <SvgDeadPixelRepair className="max-w-full px-3 md:px-10" />

      <p>
        On the page you will open by clicking the{" "}
        <code>Repair Dead Pixels</code> button below, it is explained step by
        step how to remove your dead pixels by pushing down softly.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/dead-pixel-fix"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Repair Dead Pixels</span>
        </Link>
      </div>
      <h2>How to repair stuck pixel, fix stuck pixel problem?</h2>
      <p>
        The colors that don't change on your screen,{" "}
        <strong className="italic font-bold">
          especially if their color isn't black or white,
        </strong>{" "}
        doesn't always mean they're dead pixels. Pixels that remain in one color
        for a long time may get stuck in colors such as{" "}
        <span className="text-red-600">red</span>,{" "}
        <span className="text-green-400">green</span>,{" "}
        <span className="text-blue-600">blue</span>.
      </p>
      <SvgStuckPixelRepair className="max-w-full px-3 md:px-10" />
      <p>
        There is a free repair tool for stuck pixels on the page that opens when
        you click the <code>Repair Stuck Pixel</code> button below. In addition
        to informing about the use of the{" "}
        <strong>stuck pixel repair tool</strong>, you can also find information
        on what the stuck pixel is and the difference between stuck pixel and
        dead pixel on the stuck pixel repair page.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/stuck-pixel-fix"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Repair Stuck Pixel</span>
        </Link>
      </div>
    </>
  )
}
export default HomePage
